.container{
    margin: auto;
    margin-bottom: 50px;
    max-width: 300px;
    display: grid;
    grid-template-columns: auto auto auto;
    border: 1px solid black;
}
.box{
    border: 1px solid black;
    text-align: center;
    max-width: 100px;
    min-width: 98px;
    display: block;
    font-size: 2em;
    height: 50px;
    cursor: pointer;
    max-height: 50px;
    padding-block-start: 0.67em;
    padding-block-end: 0.67em;
    padding-inline-start: 0px;
    padding-inline-end: 0px;
    font-weight:500;
}
.won{
    background-color: rgb(74, 179, 74);
}
.content{
    margin: auto;
}

.button{
    border-radius: 5px;
    border: 1px solid white;
    box-shadow: 2px 2px black;
    background-color: rgb(24, 127, 211);
    color: white;
    font-weight: 600;
    width: 100px;
    height: 25px;
    font-size: 14px;
    cursor: pointer;
  }